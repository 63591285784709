<template>
  <v-card
    class="min-h-full mx-auto mt-8 common_background"
  >
    <v-card-title>
      <h2>
        <b>{{ $t("My Profile") }}</b>
      </h2>
    </v-card-title>
    <v-card-text>
      <div v-if="getCurrentUser" class="pa-4">
        <span>
          Email: {{ getCurrentUser().email }}
        </span>
        <br />
        <span>
          User Email Verified: {{ getCurrentUser().emailVerified?'Yes': 'No' }}
        </span>
        <span v-if="getCurrentUser().phoneNumber">
          Phone No: {{ getCurrentUser().phoneNumber }}
        </span>
        <br />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import firebase from 'firebase'

export default {
  name:'Profile',
  methods: {
    getCurrentUser() {
      return firebase.auth().currentUser
    },
  },
}
</script>
